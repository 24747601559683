const modifyUtms = (routerItems) => {
  if (Object.keys(routerItems).length) {
    /* Key name matching */

    if (routerItems["Partner_Account"]) {
      routerItems["Partner_Account__c"] = routerItems["Partner_Account"];
    }

    if (routerItems["gclid"]) {
      routerItems["GCLID__c"] = routerItems["gclid"];
    }
  
    /* Data standardization */

    if (routerItems["utm_medium"]) {
      if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" || process.env.NODE_ENV === "development") {
        console.log("routerItems[utm_medium] (Pre-standardization):", routerItems["utm_medium"]);
      }
      /* Transform routerItems["utm_medium"] to all lowercase, replace all dashes, underscores, and escaped HTML spaces with a single space */
      const utmMediumCompare = routerItems["utm_medium"].toLowerCase().replace(/[-_]|%20/g, " ").replace(/ +/g, " ").trim();
      /* utm_medium values must match a picklist field in Salesforce */
      const utmMediumTransforms = {
        "abnormal hosted email": "Abnormal Hosted Email",
        "abnormal hosted webinar": "Abnormal Hosted Webinar",
        "abnormal virtual conference": "Abnormal Virtual Conference",
        "cognism": "Cognism",
        "community": "Community",
        "content syndication": "Content Syndication",
        "digital ads": "Digital Ads",
        "direct mail": "Direct Mail",
        "email campaign": "Email Campaign",
        "in app": "In-App",
        "industry event large": "Industry Event - Large",
        "industry event small/medium": "Industry Event - Small/Medium",
        "organic social": "Organic Social",
        "organic website": "Organic Website",
        "organic website abnormal intelligence": "Organic Website - Abnormal Intelligence",
        "other": "Other",
        "outbound ae": "Outbound - AE",
        "outbound bdr": "Outbound - BDR",
        "paid lead": "Paid Lead",
        "paid search": "Paid Search",
        "paid social": "Paid Social",
        "partner / channel": "Partner / Channel",
        "partner / channel marketing": "Partner / Channel Marketing",
        "partner conference": "Partner Conference",
        "partner event hosted": "Partner Event Hosted",
        "partner event sponsored": "Partner Event Sponsored",
        "pr / ar": "PR / AR",
        "promotion": "Promotion",
        "referral": "Referral",
        "referral board / advisor": "Referral - Board / Advisor",
        "referral customer": "Referral - Customer",
        "regional conference": "Regional Conference",
        "regional event hosted": "Regional Event Hosted",
        "regional event sponsored": "Regional Event Sponsored",
        "retargeting": "Retargeting",
        "safebase trust platform": "SafeBase - Trust Platform",
        "sponsored email": "Sponsored Email",
        "sponsored webinar": "Sponsored Webinar",
        "support": "Support",
        "survey": "Survey",
        "usergems": "UserGems",
        "zoominfo": "Zoominfo",
      }
      routerItems["utm_medium"] = utmMediumTransforms[utmMediumCompare] || routerItems["utm_medium"];
      if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" || process.env.NODE_ENV === "development") {
        console.log("routerItems[utm_medium] (Post-standardization):", routerItems["utm_medium"]);
      }
    }

    if (routerItems["utm_source"]) {
      /* Transform routerItems["utm_source"] to all lowercase, replace all dashes, underscores, and escaped HTML spaces with a single space */
      const utmSourceCompare = routerItems["utm_source"].toLowerCase().replace(/[-_]|%20/g, " ").replace(/ +/g, " ").trim();
      /* All utm_source values for search engines, social media sites and partners should ideally be standardized */
      /* Fall back to the original value if it doesn't match a standard */
      const utmSourceTransforms = {
        "google": "Google",
        "bing": "Bing",
        "yahoo": "Yahoo",
        "duckduckgo": "DuckDuckGo",
        "baidu": "Baidu",
        "yandex": "Yandex",
        "naver": "Naver",
        // "facebook": "Facebook",
        // "twitter": "Twitter",
        // "linkedin": "LinkedIn",
        // "instagram": "Instagram",
        // "pinterest": "Pinterest",
        // "youtube": "YouTube",
        // "other": "Other"
      }
      routerItems["utm_source"] = utmSourceTransforms[utmSourceCompare] || routerItems["utm_source"];
    }

  }
  return routerItems
}

export default modifyUtms
