import clsx from "clsx";
import parse from "html-react-parser";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";

import fetchForm from "../lib/fetchForm";

import {
  Box,
  Checkbox,
  CircularProgress,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select, Text, Textarea
} from "@chakra-ui/react";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import { IncomingWebhook } from "@slack/webhook";
import cookieCutter from "cookie-cutter";
import { TO_ABBREVIATED, TO_NAME, convertRegion } from "../lib/convertRegion";
import modifyUtms from "../lib/modifyUtms";
import { ArrowRight } from "./Icons";

const stringyTypes = ["text", "telephone", "email", "password", "date", "datetime", "datetime-local"];

const EmbeddedForm = ({
	form,
	formData = null,
	formValues = null,
	overrideButtonText = null,
	transparentInputs = false,
	outlineButton = false,
  onSuccess = () => null,
  usePlaceholders = true,
	buttonTextColor = null,
  legacyButtonBackgroundColor = null,
  darkMode = false,
  darkBackground = false,
	redirect = true,
  inline = false,
}) => {
  const [targetFormData, setTargetFormData] = useState(formData);
  const rowSpacing = "16px";

	// Use a state variable to track whether the form has been submitted successfully+2
	// const [formSuccessfullySubmitted, setFormSuccessfullySubmitted] =
  //   useState(false);
  
  // Get current timestamp as a Date object in local timezone
  const localDatetime = new Date();
  // Move the localDatetime one hour forward
  localDatetime.setHours(localDatetime.getHours() + 1);
  localDatetime.setMinutes(0);
  localDatetime.setSeconds(0);
  // console.log("Local Datetime", localDatetime);

  const [countryValue, setCountryValue] = useState("");
  const [showState, setShowState] = useState(false);

  let formDetails;
	if (formValues) {
		formDetails = formValues;
	} else {
		formDetails = form.formDetails[0];
	}

  const [urlParams, setUrlParams] = useState(null); /* UTM params from the utmParams cookie + query params */
  const [utmCampaign, setUtmCampaign] = useState({}); /* utm_campaign gets specific treatment, hold each version of it (Cookie, URL) here */
  const [savedFormValues, setSavedFormValues] = useState(null); /* Form values from the formValues cookie */
  const [formCompleteTransforms, setFormCompleteTransforms] = useState({});

  /* Get the router object, process query params */
  const router = useRouter();
  const basePath = router.asPath.split("?")[0].split("#")[0]
  let params = ""
  if (router.asPath.includes("?")) {
    const paramArray = router.asPath.split("?").join("&").split("&")
    paramArray.map((queryString, index) => {
      // remove hash from query string
      if (queryString.includes("#")) {
        paramArray[index] = queryString.replace(/#.*/, "")
      }
    })
    //remove basePath from param array
    paramArray.shift()
    //concatenate query params
    params = "?" + paramArray.join("&")
  }

	useEffect(() => {
    /* Fetch utmParams cookie, URL parameters from the router query, and formValues cookie for use in form fields */
    const utmParams = cookieCutter.get("utmParams") ? JSON.parse(cookieCutter.get("utmParams")) : {};
    const routerItems = modifyUtms(router.query) || {};

    /* Set utm_campaign separately since it gets a different priority handling for the initial field value */
    setUtmCampaign({
      ...(utmParams?.utm_campaign && { "cookie": utmParams.utm_campaign }),
      ...(routerItems?.utm_campaign && { "url": routerItems.utm_campaign })
    });

    /* Set the urlParams object, omitting utm_campaign */
    setUrlParams({
      ...utmParams,
      ...routerItems,
    });
    /* Remove utm_campaign from the utmParams and routerItems objects */
    setUrlParams(currentParams => {
      const { utm_campaign, ...rest } = currentParams;
      return rest;
    });

    /* Process saved form values from the formValues cookie */
    if (cookieCutter.get("formValues")) {
      const savedFormValues = JSON.parse(cookieCutter.get("formValues"));
      setSavedFormValues(savedFormValues);
      if (savedFormValues && savedFormValues["country"]) {
        setCountryValue(savedFormValues["country"]);
      }
    }

    // ZoomInfo FormComplete Hooks
    if (!window._zi_fc) {
      window._zi_fc = {}
    }
    window._zi_fc.onReady = (data) => {
      let customTransforms = {};
      // If there are inputs mapped in the form
      if (data?.inputs?.length > 0) {
        // Loop through the mapped inputs
        data.inputs.forEach((input) => {
          // If one of the inputs is a state field
          if (input.toLowerCase().includes("name='state'") || input.toLowerCase().includes("name=\"state\"")) {
            // Flag the form to do special FormComplete transforms for state
            customTransforms = {
              ...customTransforms,
              ...{ "state": true },
            };
          }
        });
        setFormCompleteTransforms(fcTransforms => ({ ...fcTransforms, ...customTransforms }));
      }
    }
  }, [router.query, setCountryValue]);
  //console.log(urlParams);

	const getForm = async (formId) => {
    const formData = await fetchForm({ formId: formId });
		return await formData;
	};

	// If we haven't been provided formData, we need to fetch it
	if (!targetFormData) {
		// Use the form id specified by the selected form in the pageEntry (if there is one)
		const formId = formDetails.formId;

		if (formId) {
			getForm(formId).then((data) => setTargetFormData(data));
		}
	}

	const formRef = useRef(null);

	const buttonText = overrideButtonText ? overrideButtonText : form.buttonText;

  // Helper function to combine validators on a single field
  const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

  // Prevent submission with non-business/company domains
  const isCompanyEmail = (value) =>
    value &&
    ( value.includes("@gmail.") ||
      value.includes("@hotmail.") ||
      value.includes("@live.") ||
      value.includes("@yahoo.") ||
      value.includes("@outlook.") ||
      value.includes("@aol."))
      ? "Please use a company email address"
      : null;
  
  // Prevent submission with competitor domains
  const isCompetitorEmail = (value) =>
    value &&
    ( value.includes("@cisco.") ||
      value.includes("@mimecast.") ||
      value.includes("@proofpoint.") ||
      value.includes("@agari.") ||
      value.includes("@area1security.") ||
      value.includes("@armorblox.") ||
      value.includes("@avanan.") ||
      value.includes("@darktrace.") ||
      value.includes("@greathorn.") ||
      value.includes("@inky.") ||
      value.includes("@ironscales.") ||
      value.includes("@material.security") ||
      value.includes("@perception-point.io") ||
      value.includes("@tessian.") ||
      value.includes("@vadesecure.") ||
      value.includes("@clearedin.") ||
      value.includes("@egress.") ||
      value.includes("@fortra.") ||
      value.includes("@helpsystems.") ||
      value.includes("@cloudflare.") ||
      value.includes("@titanhq.") ||
      value.includes("@checkpoint.") ||
      value.includes("@barracuda.") ||
      value.includes("@trendmicro.") ||
      value.includes("@slashnext.") ||
      value.includes("@fortinet."))
      ? "Please use a valid email address"
      : null;

	// If formDetails isn't defined just return null
	if (!formDetails) return null;

	const updateCountryValue = (e) => {
		if (e == "United States") {
			setShowState(true);
		} else {
			setShowState(false);
		}
		setCountryValue(e);
	};

	// TODO: handle the form submission
	const onSubmit = async (values) => {
    // setIsSubmitting(true);

    /* async sleep function (if needed)
    const sleep = async (num) => {
      return await new Promise((resolve) => setTimeout(resolve, num));
    };*/
    //await sleep(3000);

    // Parse through values and for any with dataType of datetime-local, convert to ISO string
    Object.keys(values).forEach((field) => {
      if (targetFormData.find((x) => x.remoteIdRest === field).dataType.toLowerCase().includes('datetime') && !!values[field]) {
        values[field] = new Date(values[field]).toISOString();
      } else if (field === "state" && formCompleteTransforms["state"] && !!values[field]) {
        // console.log("Located State Field: ",values[field]);
        values[field] = convertRegion(values[field], TO_ABBREVIATED);
      }
    });

		const postData = {};
		postData["fields"] = values;
		postData["formId"] = formDetails.formId;
    postData["marketoCookie"] = cookieCutter.get("_mkto_trk");
    postData["pageUrl"] = window.location.href;

    if (window?.ga && process.env.NODE_ENV !== "development") {
      window.ga(function () {
        const tracker = window.ga.getAll()[0];
        const trackingId = tracker.get("trackingId");
        postData["fields"]["GATRACKID__c"] = trackingId;
        const clientId = tracker.get("clientId");
        postData["fields"]["GACLIENTID__c"] = clientId;
        const userId = tracker.get("userId");
        postData["fields"]["GAUSERID__c"] = userId;
      });
    }
    
    let result;

    // Debug Post Data Submission, Sent to Slack + Marketo
    if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" || process.env.NODE_ENV === "development") {
      console.log("Post Data :", postData)
    }

    if (
      process.env.NEXT_PUBLIC_SLACK_WEBHOOK /* Located in Vercel Environment Variables */
    ) {
      const post = await fetch(
        `${process.env.NEXT_PUBLIC_CMS_URL}/api/submitForm`,
        {
          body: JSON.stringify(postData),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      result = await post.json();

			const webhookUrl = process.env.NEXT_PUBLIC_SLACK_WEBHOOK;

			const webhook = new IncomingWebhook(webhookUrl);
			delete webhook["axios"].defaults.headers["User-Agent"];
			webhook["axios"].defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      
			const fieldsBlocks = [
        postData.fields["email"] ? {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*email*: ${postData.fields["email"]}`,
          },
        } : {
          type: "header",
          text: {
            type: "plain_text",
            text: `No Form Data`,
            emoji: true,
          },
        }
      ];

      Object.keys(postData.fields).forEach((field) => {
        if (!!postData.fields[field] && field !== "email") {
          fieldsBlocks.push({
            type: "section",
            text: {
              type: "mrkdwn",
              text: `*${field}*: ${postData.fields[field]}`,
            },
          });
        }
      });

      if (result.result[0]?.reasons === undefined) {
				webhook.send({
					attachments: [
						{
							color: "#00ff00",
							blocks: [
								{
									type: "header",
									text: {
										type: "plain_text",
										text: `Form Submission: ${form.title}`,
										emoji: true,
									},
                },
                ...fieldsBlocks,
								{
									type: "section",
									text: {
										type: "mrkdwn",
										text: `*Result:* ${
											result.result[0].status
										}\n*Reason:*\n\`\`\`${JSON.stringify(
											result.result[0]?.reasons
										)}\`\`\``,
									},
								},
								{
									type: "section",
									text: {
										type: "mrkdwn",
										text: `*Form Data:*\n\`\`\`${JSON.stringify(
											postData
										)}\`\`\``,
									},
								},
								{
									type: "section",
									text: {
										type: "mrkdwn",
										text: `*Referral URL:* ${window.location.href}\n\n<https://app-sj31.marketo.com/leadDatabase/loadLeadDetail?leadId=${result.result[0].id}|View Lead in Marketo>`,
									},
								},
							],
						},
					],
				});
			} else {
				webhook.send({
					attachments: [
						{
							color: "#ff0000",
							blocks: [
								{
									type: "header",
									text: {
										type: "plain_text",
										text: "Website Form Submission Failed",
										emoji: true,
									},
								},
                {
                  type: "section",
                  text: {
                    type: "mrkdwn",
                    text: `*Users Notified: <@U05JA8KKC4T>\n*Form Name*: ${form.title} \n*Referral URL:* ${window.location.href}`,
                  },
                },
								,
								{
									type: "section",
									text: {
										type: "mrkdwn",
                    text: `\`\`\`${JSON.stringify(result)}\`\`\`\``,
									},
								},
							],
						},
					],
				});
			}
    } else {
      result = { success: true };
    }

    if (result.success === true) {
      /* Set expiration date for cookies */
      const expireDate = new Date();
      expireDate.setDate(expireDate.getDate() + 60);

      /* Filter out hidden fields */
      const visibleFields = targetFormData.filter(
        (x) => x.dataType !== "hidden"
      );

      /* Map all visible fields to an object */
      const storedValues = {};
      visibleFields.map((field) => (storedValues[field.remoteIdRest] = values[field.remoteIdRest]));

      /* Merge the new stored values into the existing cookie values */
      const storedFormValues = {
        ...(cookieCutter.get("formValues") &&
          JSON.parse(cookieCutter.get("formValues"))),
        ...storedValues,
      };

      /* Save the updated cookie */
      cookieCutter.set("formValues", JSON.stringify(storedFormValues), {
        expires: expireDate,
      });

      // console.log(cookieCutter.get("formValues"));
      // Debug Form Fill Data
      // if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" || process.env.NODE_ENV === "development") {
      //   console.log("Form Fill Label :", form.title)
      //   console.log("Form Fill Value :", window.location.href)
      // }
      
      if (window.dataLayer && process.env.NODE_ENV !== "development") {
        // Fire Google Form Fill Event
        window.dataLayer.push({
          event: "event",
          eventProps: {
            category: "Form",
            action: "Form Fill",
            label: form.title,
            value: window.location.href,
          },
        });
      }

      // Update state
      // console.log(result);
      // setIsSubmitting(false);
      // setIsSubmitted(true);

      // Update the url shallowly
      router.push(`${basePath + params + (params ? "&": "?" )}form=success`, undefined, { shallow: true });

      // Fire the callback fn
      onSuccess();
    } else {
      router.push(`${basePath + params + (params ? "&": "?" )}form=error`, undefined, { shallow: true });

      //TODO: display and handle errors
		}

		// TODO: alternatively, fire an event to Google Tag Manager test
	};

	// const fieldsData = formData.filter((field) => field.dataType !== "htmltext"); // filter out anything we don't want to render
	//console.log(targetFormData);

	return (
		<Box className={clsx("EmbeddedForm", { "w-full": inline })}>
			{!!targetFormData && targetFormData.length && (
				<Form
					onSubmit={onSubmit}
					formDetails={formDetails}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
            <Flex
              as="form"
              className="EmbeddedForm__form"
              flexDir={{
                base: "column",
                md: (inline ? "row" : "column")
              }}
              onSubmit={handleSubmit}
              width="100%"
              alignItems="flex-start"
              id={`form-${formDetails.formId}`}
              data-form-id={formDetails.formId}
              ref={formRef}
						>
              {targetFormData.map((fieldData) => {
                // Set the field type to datetime-local if it's datetime
                fieldData.dataType = fieldData.dataType === "datetime" ? "datetime-local" : fieldData.dataType;
                
								const isStringy = stringyTypes.find((t) =>
									fieldData.dataType.includes(t)
								);

								const isSelect = fieldData.dataType === "select";
                const isEmail = fieldData.dataType === "email";
                const isDateTime = (fieldData.dataType === "datetime" || fieldData.dataType === "datetime-local");
								const isCheckbox = fieldData.dataType === "checkbox";
								const isHidden = fieldData.dataType === "hidden";

								return (
									<Field
										key={fieldData.id}
                    name={fieldData.remoteIdRest}
                    validate={isEmail ? composeValidators(isCompanyEmail,isCompetitorEmail) : null}
                    type={fieldData.dataType}
                    initialValue={
                      /* Country Value */
                      fieldData.remoteIdRest === "country" && countryValue
                        ? countryValue
                        /* Saved Form Values */
                        : savedFormValues && !!savedFormValues[fieldData.remoteIdRest]
                        ? savedFormValues[fieldData.remoteIdRest]
                        /* URL Params w/UTM Cookie Fallback (not used for utm_campaign) */
                        : urlParams && !!urlParams[fieldData.remoteIdRest]
                        ? urlParams[fieldData.remoteIdRest]
                        /* URL Params w/o UTM Cookie Fallback (utm_campaign only) */
                        : fieldData.remoteIdRest === "utm_campaign" && !!utmCampaign["url"]
                        ? utmCampaign["url"]
                        /* Field Value Defaults */
                        : formDetails[fieldData.remoteIdRest]
                        ? formDetails[fieldData.remoteIdRest]
                        /* UTM Cookie (utm_campaign only) */
                        : fieldData.remoteIdRest === "utm_campaign" && !!utmCampaign["cookie"]
                        ? utmCampaign["cookie"]
                        /* Browser Autofill */
                        : fieldData.autoFill
                        ? fieldData.autoFill.value
                        : ""
										}
									>
										{({ input, meta }) => (
                      <Box
                        className="EmbeddedForm__fieldWrapper"
                        flexGrow={
                          inline &&
                            fieldData.dataType !== "hidden" &&
                            fieldData.dataType !== "htmltext"
                            ? 1
                            : 0
                        }
                        width={{
                          base: "100%",
                          md: (inline ? "auto" : "100%"),
                        }}
                      >
												{fieldData.dataType == "htmltext" && !!fieldData?.text && (
													<Box
                            className={clsx("EmbeddedForm__htmlText")}
                            mb={rowSpacing}
													>
                            <Prose>{parse(fieldData.text)}</Prose>
													</Box>
												)}
												{isStringy &&
													fieldData.dataType != "htmltext" &&
                          fieldData.dataType != "textArea" && (
                            <FormControl
                              className={clsx("EmbeddedForm__formControl")}
                              isRequired={fieldData.required}
                              //mb={inline ? rowSpacing : "0"}
                              mb={rowSpacing}
                            >
                              <Box
                                className={clsx(
                                  "EmbeddedForm__field",
                                  "bg-white-01",
                                  {
                                    "bg-white-01/10 text-white-01":
                                      transparentInputs && !darkMode,
                                    "bg-black-01/10 text-black-01":
                                      transparentInputs && darkMode,
                                    "text-black-01": !transparentInputs,
                                  }
                                )}
                                borderColor={meta.error && meta.touched ? "#CB0A0A" : "#fff"}
                                borderWidth="1px"
                              >
                                <FormLabel
                                  className={clsx(
                                    "EmbeddedForm__label",
                                    "text-xs",
                                    "opacity-50",
                                    "text-current",
                                  )}
                                  display={usePlaceholders ? "none" : "block"}
                                >
                                  {fieldData.label}
                                </FormLabel>
                                <Input
                                  {...input}
                                  type={
                                    fieldData.dataType === "telephone"
                                      ? "tel"
                                      : fieldData.dataType
                                  }
                                  className={clsx(
                                    "EmbeddedForm__input",
                                    "form-input",
                                    "w-full",
                                    { "pt-0": !usePlaceholders },
                                    "bg-transparent"
                                  )}
                                  minHeight="54px"
                                  placeholder={
                                    usePlaceholders
                                      ? fieldData.label.replace(":", "")
                                      : null
                                  }
                                  // if isDatetime then make min current day and time in local timezone
                                  min={isDateTime ? localDatetime.toISOString().slice(0, -8) : null}
                                />
                                {meta.error && meta.touched && (
                                  <Text p="7px 16px" lineHeight="1.5" color="#CB0A0A" fontSize="12px" borderTop="1px solid red">
                                    {meta.error}
                                  </Text>
                                )}
                              </Box>
                            </FormControl>
													)}
												{isStringy && fieldData.dataType === "textArea" && (
                          <FormControl
                            className={clsx("EmbeddedForm__formControl", {
                              grow:
                                inline &&
                                fieldData.dataType !== "hidden" &&
                                fieldData.dataType !== "htmltext",
                            })}
                            isRequired={fieldData.required}
                            mb={rowSpacing}
                          >
                            <Box
                              className={clsx(
                                "EmbeddedForm__field",
                                "bg-white-01",
                                {
                                  "bg-white-01/10 text-white-01":
                                    transparentInputs && !darkMode,
                                  "bg-black-01/10 text-black-01":
                                    transparentInputs && darkMode,
                                }
                              )}
                              borderColor={meta.error && meta.touched ? "#CB0A0A" : "#fff"}
                              borderWidth="1px"
                            >
                              <FormLabel
                                className={clsx(
                                  "EmbeddedForm__label",
                                  "text-xs",
                                  "opacity-50",
                                  "px-4 pt-2",
                                  "text-current",
                                  { hidden: usePlaceholders }
                                )}
                              >
                                {fieldData.label}
                              </FormLabel>
                              <Textarea
                                name={fieldData.remoteIdRest}
                                {...input}
                                // rows={4}
                                className={clsx(
                                  "EmbeddedForm__input",
                                  "form-input",
                                  "w-full",
                                  { "pt-0": !usePlaceholders },
                                  "bg-transparent"
                                )}
                                placeholder={
                                  usePlaceholders
                                    ? fieldData.label.replace(":", "")
                                    : null
                                }
                              />
                              {meta.error && meta.touched && (
                                <span
                                  className={clsx(
                                    "EmbeddedForm__error p-1.5 block text-red text-xs italic"
                                  )}
                                >
                                  {meta.error}
                                </span>
                              )}
                            </Box>
                          </FormControl>
												)}
                        {isHidden && (
                          <FormControl
                            className={clsx("EmbeddedForm__formControl", {
                              grow:
                                inline &&
                                fieldData.dataType !== "hidden" &&
                                fieldData.dataType !== "htmltext",
                            })}
                            isRequired={fieldData.required}
                          >
                            <Input
                              name={fieldData.remoteIdRest}
                              {...input}
                              type="hidden" // TODO: show for now, for testing
                              className={clsx(
                                "EmbeddedForm__hiddenInput",
                                "form-input",
                                "w-full",
                                "bg-white-01"
                              )}
                              onChange={(e) => {
                                handleChange(e);
                                input.onChange(e);
                              }}
                            />
                          </FormControl>
                        )}
                        {isSelect && (
                          (fieldData.remoteIdRest == "state" && showState) || fieldData.remoteIdRest != "state"
                        ) && (
                          <FormControl
                            className={clsx("EmbeddedForm__formControl", {
                              grow:
                                inline &&
                                fieldData.dataType !== "hidden" &&
                                fieldData.dataType !== "htmltext",
                            })}
                            isRequired={fieldData.required}
                            mb={rowSpacing}
                          >
                            <Box
                              className={clsx(
                                "EmbeddedForm__field",
                                "bg-white-01",
                                {
                                  "bg-white-01/10 text-white-01":
                                    transparentInputs && !darkMode,
                                  "bg-black-01/10 text-black-01":
                                    transparentInputs && darkMode,
                                }
                              )}
                              borderColor={meta.error && meta.touched ? "#CB0A0A" : "#fff"}
                              borderWidth="1px"
                            >
                              {((fieldData.remoteIdRest == "state" && showState) ||
                                fieldData.remoteIdRest != "state") && (
                                <FormLabel
                                  className={clsx(
                                    "EmbeddedForm__label",
                                    "text-xs",
                                    "opacity-50",
                                    "bg-white-01",
                                    {
                                      hidden:
                                        usePlaceholders &&
                                        (fieldData.remoteIdRest === "state" ||
                                          fieldData.remoteIdRest === "country"),
                                    }
                                  )}
                                >
                                  {fieldData.label}
                                </FormLabel>
                              )}

                              {fieldData.remoteIdRest == "country" && (
                                <Select
                                  {...input}
                                  value={countryValue}
                                  onChange={(e) => {
                                    updateCountryValue(e.target.value);
                                  }}
                                  className={clsx(
                                    "EmbeddedForm__country",
                                    "form-select",
                                    "w-full",
                                    "text-black-01",
                                    "bg-white-01"
                                  )}
                                >
                                  {fieldData.fieldMetaData.values.map(
                                    (option) => {
                                      return (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                          disabled={
                                            option.label === "Select..."
                                          }
                                        >
                                          {option.label === "Select..." &&
                                          usePlaceholders
                                            ? `Choose a ${fieldData.label}`
                                            : option.label}
                                        </option>
                                      );
                                    }
                                  )}
                                </Select>
                              )}

                              {showState && fieldData.remoteIdRest == "state" && (
                                <Select
                                  {...input}
                                  className={clsx(
                                    "EmbeddedForm__state",
                                    "form-select",
                                    "w-full",
                                    "text-black-01"
                                  )}
                                >
                                  {fieldData.fieldMetaData.values.map(
                                    (option) => {
                                      return (
                                        <option
                                          key={option.value}
                                          value={
                                            formCompleteTransforms[fieldData.remoteIdRest]
                                              ? convertRegion(option.value, TO_NAME)
                                              : option.value
                                          }
                                          disabled={
                                            option.label === "Select..."
                                          }
                                        >
                                          {option.label === "Select..." &&
                                          usePlaceholders
                                            ? `Choose a ${fieldData.label}`
                                            : option.label}
                                        </option>
                                      );
                                    }
                                  )}
                                </Select>
                              )}

                              {fieldData.remoteIdRest != "country" &&
                                fieldData.remoteIdRest != "state" && (
                                  <Select
                                    {...input}
                                    className={clsx(
                                      "EmbeddedForm__select",
                                      "form-select",
                                      "w-full",
                                      "text-black-01"
                                    )}
                                  >
                                    {fieldData.fieldMetaData.values.map(
                                      (option) => {
                                        return (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Select>
                                )}

                              {meta.error && meta.touched && (
                                <Text p="7px 16px" lineHeight="1.5" color="#CB0A0A" fontSize="12px" borderTop="1px solid red">
                                  {meta.error}
                                </Text>
                              )}
                            </Box>
                          </FormControl>
												)}
                        {isCheckbox && (
                          <FormControl
                            className={clsx("EmbeddedForm__formControl", {
                              grow:
                                inline &&
                                fieldData.dataType !== "hidden" &&
                                fieldData.dataType !== "htmltext",
                            })}
                            isRequired={fieldData.required}
                            //mb={inline ? rowSpacing : "0"}
                            mb={rowSpacing}
                          >
                            <Checkbox
                              name={fieldData.remoteIdRest}
                              defaultChecked={fieldData.defaultValue[0] === "y"}
                              {...input}
                              variant={darkBackground ? "darkBackground" : "default"}
                              colorScheme={darkBackground ? "white" : "purple"}
                              spacing="1rem"
                            >
                              {!!fieldData?.label && (<Prose>{parse(fieldData.label)}</Prose>)}
                            </Checkbox>

                            {meta.error && meta.touched && (
                              <span>{meta.error}</span>
                            )}

                            {meta.error && meta.touched && (
                              <span>{meta.error}</span>
                            )}
                          </FormControl>
                        )}
                      </Box>
                    )}
                  </Field>
								);
							})}

							<Box
								className={clsx("EmbeddedForm__buttons", {
									shrink: inline,
								})}
							>
								<button
									type="submit"
									// disabled={submitting || formSuccessfullySubmitted}
									disabled={submitting}
									className={clsx(
										"EmbeddedForm__button",
                    "flex items-center justify-center ml-auto",
                    {
                      "h-14 px-6": inline,
                      "h-11 px-5": !inline,
                    },
										{
											"bg-black-01":
												!legacyButtonBackgroundColor && !outlineButton,
											"border hover:bg-white-01 hover:text-black-01 hover:border-white-01":
												outlineButton,
											"text-white-01": !buttonTextColor,
										},
										"rounded-sm"
                  )}
                  style={{
                    backgroundColor: legacyButtonBackgroundColor,
                    color: buttonTextColor,
                  }}
								>
                  {submitting
                    ? "Submitting..."
                    : buttonText
                    ? buttonText
                    : "Submit"}
									<Box
										className={clsx(
											"EmbeddedForm__buttonIcon",
											"ml-4",
											"transition-transform group-hover:translate-x-1"
										)}
									>
										<ArrowRight />
									</Box>
								</button>
							</Box>
						</Flex>
					)}
				/>
			)}
		</Box>
	);
};

export default EmbeddedForm;
