import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "./tailwind.config";

const tailwind = resolveConfig(tailwindConfig);

import { extendTheme } from "@chakra-ui/react";
import { withProse } from "@nikolovlazar/chakra-ui-prose";

const FormControl = {
	baseStyle: {
		backgroundColor: "#FFFFFF",
  },
};

const FormLabel = {
	variants: {
		default: {
			borderRadius: 0,
			border: "none",
			backgroundColor: "#FFFFFF",
			margin: 0,
			fontSize: "12px",
      color: "#00000080",
      lineHeight: "16px",
			paddingTop: "7px",
			paddingLeft: "16px",
		},
	},
	defaultProps: {
		variant: "default",
	},
};

const Input = {
  baseStyle: {
    field: {
      borderRadius: 0,
      border: "none",
      color: "#000000",
      backgroundColor: "#FFFFFF",
    },
  },
	variants: {
    default: {
      field: {
        height: "31px",
        paddingTop: "2px",
        paddingBottom: "5px"
			},
			//addon: {},
			//element: {},
    },
    slim: {
      field: {
        paddingTop: "7px",
        paddingBottom: "7px",
        height: "42px",
      }
    },
	},
	defaultProps: {
		variant: "default",
	},
};

const Select = {
	variants: {
		default: {
			field: {
				borderRadius: 0,
				border: "none",
        color: "#000000",
        height: "31px",
        paddingTop: "2px",
        paddingBottom: "5px"
			},
			icon: {
				marginTop: "-12px",
				fontSize: "16px",
				color: "#000000",
			},
    },
	},
	defaultProps: {
		variant: "default",
	},
};

const Textarea = {
	variants: {
		default: {
			borderRadius: 0,
			border: "none",
			color: "#000000",
		},
	},
	defaultProps: {
		variant: "default",
	},
};

const Checkbox = {
  baseStyle: {
    control: {
      border: "1px",
      borderRadius: 0,
    },
    //icon: {},
    //container: {},
    //label: {}
  },
	variants: {
		default: {
			control: {
        borderColor: "#000000",
			},
      //icon: {},
			//container: {},
			//label: {}
    },
    darkBackground: {
      control: {
        borderColor: "#ffffff",
        colorScheme: "white",
      },
      icon: {
        color: "#000000",
      },
    }
	},
	defaultProps: {
    variant: "default",
    colorScheme: "purple"
	},
};

const Tabs = {
	parts: ["tablist", "tabpanel", "tab"],
	defaultProps: { variant: "solid" },
	baseStyle: {
		tab: {
			px: 4,
			py: 3,
			color: "red.500",
		},
	},
	sizes: {
		sm: {
			tab: {
				fontSize: 5,
			},
			tablist: {
				p: 4,
			},
		},
	},
	variants: {
		outline: {
			tablist: {
				border: "1px solid",
			},
		},
		solid: {
			tablist: {
				gap: 3,
			},
			tabpanel: {
				px: 0,
				py: 10,
				bg: "white-01",
			},
			tab: {
				borderRadius: "0.125rem 0.125rem 0 0",
				bg: "purple-gray-03",
				color: "white-01",
				px: 10,
				fontSize: "1.25rem",
				_selected: {
					bg: "white-01",
					color: "purple-02",
				},
			},
		},
	},
};

const Link = {
	baseStyle: {
		_hover: {
			textDecoration: "none",
		},
	},
};

const Button = {
	// The styles all button have in common
	baseStyle: {
		fontWeight: "normal",
		borderRadius: "0.125rem",
		whiteSpace: "normal",
		".chakra-button__icon": {
			transitionProperty: "transform",
			transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
			transitionDuration: ".15s",
		},
		_hover: {
			".chakra-button__icon": {
				transform: "translateX(.25rem)",
			},
		},
		py: 2,
		px: 5,
		lineHeight: 1.5,
	},
	variants: {
		solid: {
			_hover: {
				background: "black",
				color: "white",
			},
		},
	},
	sizes: {
		md: {
			px: 5,
			py: 2,
			h: null,
		},
	},
};

const Heading = {
	baseStyle: {
		fontWeight: "normal",
		fontFamily: "inherit",
		w: "100%",
	},
	sizes: {
		sm: {
			fontSize: "0.875rem",
			lineHeight: 1.2,
		},
		md: {
			fontSize: "1rem",
			lineHeight: 1.2,
		},
		lg: {
			fontSize: "1.125rem",
			lineHeight: 1.2,
		},
		xl: {
			fontSize: "1.25rem",
			lineHeight: 1.2,
		},
		"2xl": {
			fontSize: "1.5rem",
			lineHeight: 1.2,
		},
		"3xl": {
			fontSize: "2rem",
			lineHeight: 1.2,
		},
		"4xl": {
			fontSize: "2.25rem",
			lineHeight: 1.2,
		},
		"5xl": {
			fontSize: "3.25rem",
			lineHeight: 1.2,
		},
		"6xl": {
			fontSize: "3.75rem",
			lineHeight: 1.2,
		},
		"7xl": {
			fontSize: "4.25rem",
			lineHeight: 1.2,
		},
		"14xl": {
			fontSize: "10rem",
			lineHeight: 1.2,
		},
	},
};

const theme = extendTheme(
  {
    breakpoints: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px'
    },
		fonts: {
			body: "TWK Everett",
			heading: "TWK Everett",
		},
    colors: {
      ...tailwind.theme.colors,
      purple: {
        500: "#6863F2",
        600: "#483698",
      },
      white: {
        500: "#ffffff",
        600: "#f7f7f7",
      },
    },
		fontSizes: tailwind.theme.fontSize,
		radii: {
			base: "0",
		},
		space: {
			xxs: ".5rem",
			xs: "1rem",
			sm: "1.875rem",
			md: "3.75rem",
			lg: "7.5rem",
			xl: "10rem",
		},
		sizes: {
			full: "100%",
			"max-w-prose": "65ch",
			"max-w-xs": "320px",
			"max-w-sm": "384px",
			"max-w-md": "448px",
			"max-w-lg": "512px",
			"max-w-xl": "576px",
			"max-w-screen-half": "600px",
			"max-w-screen-sm": "640px",
			"max-w-screen-md": "768px",
			"max-w-screen-lg": "1024px",
			"max-w-screen-xl": "1240px",
			"max-w-screen-2xl": "1400px",
			"max-w-screen-3xl": "2100px",
			"1/2": "300px",
		},
		components: {
			Button,
			Heading,
			Link,
      Tabs,
			FormControl,
			FormLabel,
			Input,
			Select,
			Textarea,
			Checkbox,
		},
	},
	withProse({
		baseStyle: {
			textAlign: "left",
			a: {
				textDecoration: "underline",
			},
			p: {
				mt: 0,
				':last-child': {
					mb: 0
				}
			},
			li: {
				paddingLeft: "8px",
			},
			ul: {
        mb: "1.5rem",
				li: {
					marginY: "4px",
					"&::marker": {
						color: "inherit !important",
					},
				},
			},
			ol: {
        mb: "1.5rem",
				li: {
					marginY: "4px",
					listStyleType: "decimal",
					listStylePosition: "outside",
					"&::marker": {
						color: "inherit !important",
					},
				},
			},
			h1: {
				fontWeight: "normal",
				fontSize: { base: "1.5rem", md: "2.25rem" },
				lineHeight: 1.2,
			},
			h2: {
				fontWeight: "normal",
				fontSize: { base: "1.25rem", md: "2rem" },
				lineHeight: 1.2,
				mt: 0,
			},
			h3: {
				fontWeight: "normal",
				fontSize: { base: "1.15rem", md: "1.75rem" },
				lineHeight: 1.2,
			},
			h4: {
				fontWeight: "normal",
				fontSize: { base: "1rem", md: "1.5rem" },
				lineHeight: 1.2,
			},
			h5: {
				fontWeight: "normal",
			},
		},
	})
);

export default theme;
